import React, { useState, useEffect } from 'react';
import { getDatabase, ref, onValue, remove, update } from 'firebase/database';
import { Modal, Button, Form } from 'react-bootstrap';

const WordListManager = () => {
    const [wordLists, setWordLists] = useState([]);
    const [showEditModal, setShowEditModal] = useState(false);
    const [currentEdit, setCurrentEdit] = useState({ items: [] });
    const db = getDatabase();

    useEffect(() => {
        const wordListRef = ref(db, 'wordlist');
        onValue(wordListRef, (snapshot) => {
            if (snapshot.exists()) {
                setWordLists(Object.entries(snapshot.val()).map(([key, value]) => ({ id: key, ...value })));
            } else {
                setWordLists([]);
            }
        });
    }, []);

    const handleDelete = async (id) => {
        try {
            const wordListRef = ref(db, `wordlist/${id}`);
            await remove(wordListRef);
        } catch (error) {
            alert(`Error deleting wordlist: ${error.message}`);
        }
    };

    const handleEditChange = (e) => {
        const { name, value } = e.target;
        setCurrentEdit({ ...currentEdit, [name]: value });
    };

    const handleItemChange = (index, e) => {
        const updatedItems = [...currentEdit.items];
        updatedItems[index][e.target.name] = e.target.value;
        setCurrentEdit({ ...currentEdit, items: updatedItems });
    };

    const handleEdit = (wordList) => {
        setCurrentEdit(wordList);
        setShowEditModal(true);
    };

    const saveEdit = async () => {
        try {
            const wordListRef = ref(db, `wordlist/${currentEdit.id}`);
            await update(wordListRef, currentEdit);
            setShowEditModal(false);
        } catch (error) {
            alert(`Error updating wordlist: ${error.message}`);
        }
    };

    return (
        <div className="container mt-3">
            <h2>Word List Manager</h2>
            <table className="table">
                <thead>
                <tr>
                    <th>Naam</th>
                    <th>Catergorie</th>
                    {/* Add other fields as needed */}
                    <th>Acties</th>
                </tr>
                </thead>
                <tbody>
                {wordLists.map(list => (
                    <tr key={list.id}>
                        <td>{list.name}</td>
                        <td>{list.category}</td>
                        {/* Display other fields as needed */}
                        <td>
                            <Button variant="primary" onClick={() => handleEdit(list)}>Bewerk</Button>
                            <Button variant="danger" onClick={() => handleDelete(list.id)}>Verwijder</Button>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>

            <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Woordlijst bewerken</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {currentEdit && (
                        <Form>
                            <Form.Group className="mb-3">
                                <Form.Label>Naam</Form.Label>
                                <Form.Control type="text" name="name" value={currentEdit.name} onChange={handleEditChange} />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Category</Form.Label>
                                <Form.Control type="text" name="category" value={currentEdit.category} onChange={handleEditChange} />
                            </Form.Group>
                            {/* Editable items */}
                            {currentEdit.items.map((item, index) => (
                                <div key={index}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Item {index + 1}</Form.Label>
                                        <Form.Control type="text" name="item1" value={item.item1} onChange={(e) => handleItemChange(index, e)} />
                                        <Form.Control type="text" name="item2" value={item.item2} onChange={(e) => handleItemChange(index, e)} />
                                    </Form.Group>
                                </div>
                            ))}
                        </Form>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowEditModal(false)}>Sluiten</Button>
                    <Button variant="primary" onClick={saveEdit}>Opslaan</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default WordListManager;
