import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { UserContext } from './pages/Login/UserContext.js';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from './pages/Home/Home';
import LoginController from './pages/Login/LoginController.js';
import Navbar from './Navbar/Navbar';
import { ProtectedRoute } from './pages/Login/ProtectedRoute.js';
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getDatabase } from 'firebase/database';
import firebaseConfig from './firebase/config/firebaseConfig'; 
import { setupAuthStateListener } from './pages/Login/LoginModel.js';  
import Invullen from "./opdrachtControllers/Invul Opdracht/Invullen_Controller";
import ManagerView from "./pages/docentenManager/Manager.js";
import WordQuiz from "./opdrachtControllers/Overhoring/Overhoring.js";
import Flashcards from "./opdrachtControllers/Flitskaart/Flitskaarten";
import Woordzoeker  from "./opdrachtControllers/Woordzoeker/Woordzoeker"; 
import WordListForm from './pages/docentenManager/addOpdracht';
import WordListManager from "./pages/docentenManager/beheerOpdracht";
import WordListLayout from './pages/docentenManager/Manager';
import UserManager from './pages/docentenManager/ManageLeerling';
import ClassManager from './pages/docentenManager/beheerKlassen';
import Accountpage from './pages/Account/Account';

const firebaseApp = initializeApp(firebaseConfig);
const db = getDatabase(firebaseApp);
const auth = getAuth(firebaseApp);

function Content() {
    const [user, setUser] = useState(null);
    const [isLoading, setIsLoading] = useState(true); // Add loading state

    useEffect(() => {
        const unsubscribeFromAuth = setupAuthStateListener(auth, db, (newUser) => {
            setUser(newUser);
            setIsLoading(false); // Set loading to false once the user is loaded
        });

        return () => {
            if (typeof unsubscribeFromAuth === 'function') {
                unsubscribeFromAuth();
            }
        };
    }, []);

    if (isLoading) {
        return <div>Loading...</div>; // Or any other loading indicator
    }

    return (
        <UserContext.Provider value={{ user, setUser }}>
            <Router>
                <switch>
                <Navbar />
                    <Routes>
                        <Route path="/login" element={<LoginController />} />
                        <Route path="/" element={<ProtectedRoute element={Home} />} />
                        <Route path="/invullen" element={<ProtectedRoute element={Invullen} />} />
                        <Route path="/docent" element={<ProtectedRoute element={ManagerView} />} />
                        <Route path="*" element={<h1>Not Found</h1>} />
                        {/*<Route path="/account" element={<ProtectedRoute element={Accountpage} />} />*/}
                        <Route path="/Overhoring" element={<ProtectedRoute element={WordQuiz} />} />
                        <Route path="/Flitskaarten" element={<ProtectedRoute element={Flashcards} />} />
                        <Route path="/Woordzoeker" element={<ProtectedRoute element={Woordzoeker} />} />
                        <Route path="/wordlist/*" element={
                            <WordListLayout>
                                <Routes>
                                    <Route path="add" element={<ProtectedRoute element={WordListForm} />} />
                                    <Route path="users" element={<ProtectedRoute element={UserManager} />} />
                                    <Route path="manage" element={<ProtectedRoute element={WordListManager} />} />
                                    <Route path="ClassManager" element={<ProtectedRoute element={ClassManager} />} />
                                </Routes>
                            </WordListLayout>
                        } />
                        
                    </Routes>
                </switch>
            </Router>
        </UserContext.Provider>
    );

}

export default Content;
