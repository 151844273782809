import React, { useContext, useEffect, useState } from 'react';
import { getDatabase, ref, set, push, onValue } from 'firebase/database';
import { initializeApp } from "firebase/app";
import { UserContext } from '../Login/UserContext';
import firebaseConfig from "../../firebase/config/firebaseConfig";
import { Modal, Button, Tooltip, OverlayTrigger } from 'react-bootstrap';
import Papa from 'papaparse';



const firebaseApp = initializeApp(firebaseConfig);
const db = getDatabase(firebaseApp);

const WordListForm = () => {
    const {user} = useContext(UserContext);
    const [csvFile, setCsvFile] = useState(null);
    const [formData, setFormData] = useState({
        name: '',
        category: '',
        newCategory: '',
        categories: [],
        customLabels: {label1: 'Kolom 1', label2: 'Kolom 2'},
        items: [{item1: '', item2: ''}],
        options: {option1: false, option2: false, option3: false},
        addRowsCount: 1
    });
    const [showModal, setShowModal] = useState(false);


    useEffect(() => {
        const categoriesRef = ref(db, 'categories');
        onValue(categoriesRef, (snapshot) => {
            if (snapshot.exists()) {
                setFormData(formData => ({...formData, categories: snapshot.val()}));
            }
        });
    }, []);

    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setFormData({...formData, [name]: value});
    };
    const handleCsvFileChange = (e) => {
        setCsvFile(e.target.files[0]);
    };
    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Vul hier de waarden in voor Kolom 1 en Kolom 2.
        </Tooltip>
    );


    const handleCsvUpload = () => {
        if (csvFile) {
            Papa.parse(csvFile, {
                header: true,
                delimiter: ";",
                complete: (result) => {
                    const itemsFromCsv = result.data.map(row => ({
                        item1: row['Kolom1'] || '',
                        item2: row['Kolom2'] || ''
                    }));
                    setFormData({...formData, items: itemsFromCsv});
                }
            });
        }
    };


    const handleCheckboxChange = (e) => {
        const {name, checked} = e.target;
        setFormData({...formData, options: {...formData.options, [name]: checked}});
    };

    const handleItemChange = (index, e) => {
        const updatedItems = formData.items.map((item, i) => {
            if (i === index) {
                return {
                    ...item, [e.target.name]:
                    e.target.value
                };
            }
            return item;
        });
        setFormData({...formData, items: updatedItems});
    };
    const handleAddRows = () => {
        const newItems = Array.from({length: formData.addRowsCount}, () => ({item1: '', item2: ''}));
        setFormData({...formData, items: [...formData.items, ...newItems]});
    };

    const handleLabelChange = (e) => {
        const {name, value} = e.target;
        setFormData({
            ...formData,
            customLabels: {...formData.customLabels, [name]: value}
        });
    };

    const handleRemoveItem = (index) => {
        const updatedItems = formData.items.filter((_, i) => i !== index);
        setFormData({...formData, items: updatedItems});
    };

    const toggleModal = () => {
        setShowModal(!showModal);
    };

    const handleAddCategory = async () => {
        if (!formData.newCategory.trim()) return;

        const categoriesRef = ref(db, 'categories');
        const newCategories = [...formData.categories, formData.newCategory];
        try {
            await set(categoriesRef, newCategories);
            setFormData({...formData, newCategory: '', categories: newCategories});
        } catch (error) {
            alert(`Fout bij het toevoegen van categorie: ${error.message}`);
        }
        toggleModal();
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            // Controleer of de naam en categorie zijn ingevuld
            if (!formData.name.trim() || !formData.category.trim()) {
                alert('Vul alle velden in');
                return;
            }
            // Controleer of een geldige categorie is gekozen
            else if (formData.category === 'Kies Categorie') {
                alert('Kies een categorie');
                return;
            }
            // Controleer of er ten minste twee items zijn en of ze correct zijn ingevuld
            else if (formData.items.length < 2 || formData.items.some(item => !item.item1.trim() || !item.item2.trim())) {
                alert('Voeg minimaal 2 volledig ingevulde rijen toe');
                return;
            }
            else {
                const wordListRef = ref(db, 'wordlist');
                await set(push(wordListRef), {...formData, createdBy: user.uid, createdAt: new Date().toISOString()});
                alert('Data succesvol toegevoegd');
            }
        } catch (error) {
            alert(`Fout bij het toevoegen van data: ${error.message}`);
        }
    };

    return (
        <form onSubmit={handleSubmit} className="container mt-3">
            <div className="mb-3">
                <label htmlFor="name" className="form-label">Naam</label>
                <input
                    type="text"
                    className="form-control"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                />
            </div>

            <div className="mb-3 d-flex align-items-center">
                <select
                    className="form-select me-2"
                    id="category"
                    name="category"
                    value={formData.category}
                    onChange={handleInputChange}
                >
                    <option value="">Selecteer Categorie</option>
                    {formData.categories.map((cat, index) => (
                        <option key={index} value={cat}>{cat}</option>
                    ))}
                </select>
                <Button variant="secondary" onClick={toggleModal}>Voeg Categorie Toe</Button>
            </div>

            {showModal && (

                <Modal

                    show={showModal} onHide={toggleModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Categorie Toevoegen</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <input
                            type="text"
                            className="form-control"
                            id="newCategory"
                            name="newCategory"
                            placeholder="Nieuwe Categorie"
                            value={formData.newCategory}
                            onChange={handleInputChange}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={toggleModal}>Sluiten</Button>
                        <Button variant="primary" onClick={handleAddCategory}>Opslaan</Button>
                    </Modal.Footer>
                </Modal>
            )}
            <div className="mb-3">
                <input type="file" accept=".csv" onChange={handleCsvFileChange} />
                <button type="button" className="btn btn-info" onClick={handleCsvUpload}>Bestand uitlezen</button>
            </div>
            <div className="mb-3">
                <table className="table">
                    <thead>
                    <tr>
                        <OverlayTrigger
                            placement="top"
                            overlay={renderTooltip}>
                            <th>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="label1"
                                    value={formData.customLabels.label1}
                                    onChange={handleLabelChange}
                                />
                            </th>
                        </OverlayTrigger>
                        <OverlayTrigger
                            placement="top"
                            overlay={renderTooltip}>
                            <th>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="label2"
                                    value={formData.customLabels.label2}
                                    onChange={handleLabelChange}
                                />
                            </th>
                        </OverlayTrigger>
                        <th>Acties</th>
                    </tr>
                    </thead>
                    <tbody>
                    {formData.items.map((item, index) => (
                        <tr key={index}>
                            <td>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="item1"
                                    value={item.item1}
                                    onChange={(e) => handleItemChange(index, e)}
                                />
                            </td>
                            <td>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="item2"
                                    value={item.item2}
                                    onChange={(e) => handleItemChange(index, e)}
                                />
                            </td>
                            <td>
                                <button
                                    type="button"
                                    className="btn btn-danger"
                                    onClick={() => handleRemoveItem(index)}
                                >
                                    ×
                                </button>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
                <div className="d-flex align-items-center">
                    <input
                        type="number"
                        className="form-control me-2"
                        name="addRowsCount"
                        value={formData.addRowsCount}
                        onChange={handleInputChange}
                        min="1"
                    />
                    <button type="button" className="btn btn-secondary" onClick={handleAddRows}>Voeg Rijen Toe</button>
                </div>
            </div>
            <div className="mb-3 text-white">
                <label className="form-label">Werkt als:</label>
                <div className="form-check">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        id="option1"
                        name="option1"
                        checked={formData.options.option1}
                        onChange={handleCheckboxChange}
                    />
                    <label className="form-check-label" htmlFor="option1">
                        Invulopdracht
                    </label>
                </div>
                <div className="form-check">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        id="option2"
                        name="option2"
                        checked={formData.options.option2}
                        onChange={handleCheckboxChange}
                    />
                    <label className="form-check-label" htmlFor="option2">
                        Flitskaarten
                    </label>
                </div>
                <div className="form-check">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        id="option3"
                        name="option3"
                        checked={formData.options.option3}
                        onChange={handleCheckboxChange}
                    />
                    <label className="form-check-label" htmlFor="option3">
                        Kruiswoord
                    </label>
                </div>
            </div>

            <button type="submit" className="btn btn-primary">Voeg toe aan lijst</button>
        </form>
    );
};

export default WordListForm;
        
