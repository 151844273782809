import React, { useState, useEffect } from 'react';
import { getDatabase, ref, get, set } from 'firebase/database';
import { initializeApp } from "firebase/app";
import firebaseConfig from "../../firebase/config/firebaseConfig";
import { useLocation, useNavigate } from "react-router-dom";
import { UserContext } from "../../pages/Login/UserContext";
import './kaarten.css';

const firebaseApp = initializeApp(firebaseConfig);
const db = getDatabase(firebaseApp);

const Flashcards = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const questionID = location.state && location.state.questionID;
    const { user } = React.useContext(UserContext);
    const userID = user.uid;
    const [flashcards, setFlashcards] = useState([]);
    const [currentCard, setCurrentCard] = useState(0);
    const [isPlaying, setIsPlaying] = useState(false);
    const [userAnswer, setUserAnswer] = useState('');
    const [progress, setProgress] = useState(0);
    const [timeLeft, setTimeLeft] = useState(0);
    const transitionTime = 3000; // Total transition time for all cards in milliseconds
    const intervalTime = 3000;
    let autoPlayInterval = null; // Define autoPlayInterval here

    useEffect(() => {
        const fetchFlashcards = async () => {
            try {
                const snapshot = await get(ref(db, `wordlist/${questionID}`));
                if (snapshot.exists()) {
                    const data = snapshot.val();
                    const formattedFlashcards = data.items.map(item => ({
                        ...item,
                        isFlipped: false,
                    }));
                    setFlashcards(formattedFlashcards);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        if (questionID) {
            fetchFlashcards();
        }

        return () => {
            if (autoPlayInterval) clearInterval(autoPlayInterval);
        };
    }, [questionID]);

    useEffect(() => {
        if (isPlaying && flashcards.length > 0) {
            autoPlayInterval = setInterval(() => {
                setCurrentCard(prevCard => (prevCard + 1) % flashcards.length);
                setTimeLeft(intervalTime / 1000); // Convert to seconds
                userAnswer && setUserAnswer('');
            }, intervalTime);
        } else {
            clearInterval(autoPlayInterval);
        }

        return () => clearInterval(autoPlayInterval);
    }, [isPlaying, flashcards.length]);

    useEffect(() => {
        if (timeLeft > 0) {
            const timer = setTimeout(() => {
                setTimeLeft(prevTimeLeft => prevTimeLeft - 1);
            }, 1000);
            return () => clearTimeout(timer);
        }
    }, [timeLeft]);

    useEffect(() => {
        setProgress((currentCard / (flashcards.length - 1)) * 100);
    }, [currentCard, flashcards]);

    const handleCardClick = index => {
        setFlashcards(currentFlashcards =>
            currentFlashcards.map((card, i) =>
                i === index ? { ...card, isFlipped: !card.isFlipped } : card
            )
        );
    };

    const handleAutoPlay = () => {
        if (flashcards.length > 0) {
            setIsPlaying(!isPlaying);
        }
    };

    const handleFinish = () => {
        // Save completion status to Firebase
        const flashcardsCompletionRef = ref(db, `/users/${userID}/flashcards/${questionID}`);
        set(flashcardsCompletionRef, { completed: true })
            .then(() => {
                console.log('Flashcard completion status saved.');
                navigate('/'); // Navigate back to the home screen
            })
            .catch((error) => {
                console.error('Error saving completion status:', error);
            });
    };

    return (
        <div className="flashcards-container">
            <div className="card-counter">{currentCard + 1}/{flashcards.length}</div>
            <div className="time-bar-container">
                <div className="time-bar" style={{ width: `${(timeLeft / (transitionTime / flashcards.length / 1000)) * 100}%` }}></div>
            </div>
            <div className="progress-bar-container">
                <div className="progress-bar" style={{ width: `${progress}%` }}></div>
            </div>
            <div className="flashcards">
                {flashcards.length > 0 && flashcards.map((card, index) => (
                    <div key={index} className={`card-container ${index === currentCard ? 'main-card-container' : ''}`} onClick={() => handleCardClick(index)}>
                        <div className={`card1 ${card.isFlipped ? 'flipped' : ''}`}>
                            <div className={`front ${card.isFlipped ? '' : 'show'}`}>{card.item1}</div>
                            <div className={`back ${card.isFlipped ? 'show' : ''}`}>{card.item2}</div>
                        </div>
                    </div>
                ))}
            </div>
            <div className="user-input">
                <input type="text" value={userAnswer} onChange={(e) => setUserAnswer(e.target.value)} placeholder="Schrijf je antwoord voor jezelf op" />
            </div>
            <div className="buttons-container">
                <button className="play-button" onClick={handleAutoPlay}>{isPlaying ? 'Stop' : 'Start'}</button>
                <button className="finish-button" onClick={handleFinish}>Klaar</button>
            </div>
        </div>
    );
};

export default Flashcards;
