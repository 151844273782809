import './assets/Opdrachten.css';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getDatabase, ref, get } from 'firebase/database';
import { initializeApp } from "firebase/app";
import Modal from 'react-modal';
import firebaseConfig from "../../firebase/config/firebaseConfig";
import { ReactComponent as InvullenIcon } from './assets/pencil-square.svg';
import { ReactComponent as FlitskaartIcon } from './assets/postcard-fill.svg';
import { ReactComponent as GemaaktIcon } from './assets/postcard-fill.svg';
import './assets/Home.css';
import { useDocentName } from './getDocentname.js';




const firebaseApp = initializeApp(firebaseConfig);
const db = getDatabase(firebaseApp);


Modal.setAppElement('#root');
const ResultComponent = function(props) {
    let navigate = useNavigate();
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const { docentName, isLoading, error } = useDocentName(props.docentID);

    const openModal = () => setModalIsOpen(true);
    const closeModal = () => setModalIsOpen(false);
    let typeofquestion = "";

    const handleOptionClick = (typeofquestion) => {
        navigate('/' + typeofquestion, { state: { questionID: props.questionID } });
        closeModal();
    };

    return (
        <div className='result' onClick={openModal}>
            <div className="result-column">
                <p className="question-status">{props.questionStatus}</p>
            </div>
            <div className="result-column">
                <p className="question-name">{props.name}</p>
            </div>
            <div className="result-column">
                <p className="question-type">{props.questionType}</p>
            </div>
            <div className="result-column">
                <p className="docent-name">{isLoading ? 'Loading...' : docentName.displayName}</p>
                {/* Display error if there is an error */}
                {error && <p className="error">{error.message}</p>}
            </div>
            <div className="result-column result-icons">
                {props.questionIcons}
            </div>


            <Modal isOpen={modalIsOpen} onRequestClose={closeModal} className="custom-modal" contentLabel="Options Modal">
                <div className="modal-container">
                    {props.options.option1 && (
                        <div className="modal-column" onClick={handleOptionClick.bind(null, "Overhoring")}>
                            <InvullenIcon />
                            <h3>Overhoren</h3>
                            <p className="lead">Test je kennis met een snelle overhoring. Ideaal om te zien hoe goed je de stof beheerst.</p>
                            <button>Start overhoring</button>
                        </div>
                    )}
                    {props.options.option2 && (
                        <div className="modal-column" onClick={handleOptionClick.bind(null, "Flitskaarten")}>
                            <FlitskaartIcon />
                            <h3>Flitskaarten</h3>
                            <p className="lead">Gebruik flitskaarten om belangrijke concepten en definities op een interactieve manier te leren.</p>
                            <button>Start flitskaarten</button>
                        </div>
                    )}
                    {props.options.option3 && (
                        <div className="modal-column" onClick={handleOptionClick.bind(null, "Woordzoeker")}>
                            <GemaaktIcon />
                            <h3>Woordzoeker</h3>
                            <p className="lead">Versterk je woordenschat en spelling met een leuke en uitdagende woordzoeker.</p>
                            <button>Start woordzoeker</button>
                        </div>
                    )}
                </div>
            </Modal>

        </div>
    );
};


const SubCatogrie = function (props) {
    const [filterdValues, setFilterdValue] = useState([]);
    const handleCheckboxClick = (value, event) => {
        const updatedValues = event.target.checked
            ? [...filterdValues, value]
            : filterdValues.filter(v => v !== value);
        setFilterdValue(updatedValues);
    };

    useEffect(() => {
        if (props.id === '1') {
            filterArray1 = filterdValues;
        } else {
            filterArray2 = filterdValues;
        }
        filterArray = Array.from(new Set([...filterArray1, ...filterArray2]));
        addCatogrieFilter();
    }, [filterdValues, props.id]);

    return (
        <div>
            {props.items.map((item, index) => (
                <div key={`checkboxContainer${props.id}-${index}`}>
                    <input
                        onClick={(event) => handleCheckboxClick(item, event)}
                        type="checkbox"
                        id={`checkbox${props.id}-${index}`}
                    />
                    <label htmlFor={`checkbox${props.id}-${index}`}>{item}</label>
                </div>
            ))}
        </div>
    );
}

let filterArray = [];
let filterArray1 = [];
let filterArray2 = [];
let addCatogrieFilter;

let Home = function() {
    const [list, setList] = useState([]);
    const [searchBarInput, setSearchBarInput] = useState('');
    const [searcBarResults, setSearchBarResults] = useState([]);

    useEffect(() => {
        const dbRef = ref(db, 'wordlist');
        get(dbRef).then((snapshot) => {
            if (snapshot.exists()) {
                const wordlistArray = [];
                snapshot.forEach(childSnapshot => {
                    const key = childSnapshot.key;
                    const data = childSnapshot.val();
                    wordlistArray.push({ ...data, id: key }); // Add the key as `id`
                });
                setList(wordlistArray);
                setSearchBarResults(filterAndDisplayResults('', wordlistArray));
            } else {
                console.log("No data available");
            }
        }).catch((error) => {
            console.error(error);
        });
    }, []);


    const filterAndDisplayResults = (searchInput, items) => {
        let results = searchInput === ''
            ? [...items]
            : items.filter((item) =>
                item.name.toLowerCase().includes(searchInput.toLowerCase())
            );

        let finalResults = filterArray && filterArray.length > 0
            ? results.filter(result => filterArray.some(filter =>
                result.questionType === filter || result.questionStatus === filter
            ))
            : results;

        return finalResults.map((result, index) => {
            let icons = [];

            if (result.options.option1 === true) {
                icons.push(
                    <div key="icon1">
                        <InvullenIcon height="40px" alt="invullen" />
                        <p>Invullen</p>
                    </div>
                );
            }
            if (result.options.option2 === true) {
                icons.push(
                    <div key="icon2">
                        <FlitskaartIcon height="40px" alt="flitskaart" />
                        <p>Flitskaarten</p>
                    </div>
                );
            }
            if (result.options.option3 === true) {
                icons.push(
                    <div key="icon3">
                        <GemaaktIcon height="20px" alt="gemaakt" />
                        <p>Kruiswoord</p>
                    </div>
                );
            }

            return (
                <ResultComponent
                    key={index}
                    name={result.name}
                    questionType={result.category}
                    questionStatus={result.questionStatus}
                    questionIcons={icons}
                    questionID={result.id}
                    options={result.options}
                    docentID={result.createdBy}
                />
            );
        });
    };
    

    addCatogrieFilter = function() {
        const input = document.getElementById('searchBar');
        handleSeachBarInput({ target: { value: input.value } });
    }

    const handleSeachBarInput = function(event) {
        setSearchBarInput(event.target.value);
        setSearchBarResults(filterAndDisplayResults(event.target.value, list));
    };

    return (
        <div className="App">
            <div className='font-large' id='content'>

                <div className='d-xl-flex d-none' id='catogrie'>
                    <h2 className='pt-3'>Categorie</h2>

                    <SubCatogrie id='1' items={['Invullen', 'Flitskaart']} />
                    <SubCatogrie id='2' items={['Gemaakt', 'To Do']} />
                </div>

                <div id='container'>
                    <input
                        placeholder='Type hier je zoekopdracht'
                        id='searchBar'
                        value={searchBarInput} onChange={handleSeachBarInput}>
                    </input>
                    <div id='results'>
                        {searcBarResults}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Home;
