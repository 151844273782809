import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider, EmailAuthProvider } from 'firebase/auth';
import * as firebaseui from 'firebaseui';
import firebaseConfig from '../../firebase/config/firebaseConfig';
import 'firebaseui/dist/firebaseui.css';
import '../../Style/login.scss';
import { getDatabase, ref, get ,set, update } from 'firebase/database';
import {useContext, useState} from "react";
import {UserContext} from "./UserContext";

initializeApp(firebaseConfig);
const auth = getAuth();
const db = getDatabase();


export const signInWithFirebase = (setUser, navigate) => {
    const uiConfig = {
        signInSuccessUrl: '/home',
        signInOptions: [
            EmailAuthProvider.PROVIDER_ID
        ],
        tosUrl: '<your-tos-url>',
        privacyPolicyUrl: '<your-privacy-policy-url>',
        callbacks: {
            signInSuccessWithAuthResult: (authResult, redirectUrl) => {
                setUser(authResult.user);
                navigate('/');
                return false; 
            },
        }
    };

    const ui = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(auth);
    ui.start('#firebaseui-auth-container', uiConfig);
};

export const setupAuthStateListener = (auth, db, setUser) => {
    const unsubscribe = auth.onAuthStateChanged((firebaseUser) => {

        if (firebaseUser) {
            const userRef = ref(db, 'users/' + firebaseUser.uid);

            get(userRef).then((snapshot) => {
                if (snapshot.exists()) {
                    const userData = snapshot.val();
                    update(userRef, { lastLogin: new Date().toISOString() });
                    setUser({ ...userData, lastLogin: new Date().toISOString() });
                } else {
                    const newUser = {
                        displayName: firebaseUser.displayName || 'New User',
                        email: firebaseUser.email,
                        photoURL: firebaseUser.photoURL || 'defaultProfilePicUrl',
                        uid:firebaseUser.uid,
                        status: 1,
                        lastLogin: new Date().toISOString()
                    };
                    set(userRef, newUser);
                    setUser(newUser);
                }
            }).catch(error => {
                console.error("Error accessing database: ", error);
            });
        } else {
            setUser(null);
        }
    console.log("test");
        return unsubscribe;
    });
}