import React, { useState, useEffect } from 'react';
import { getDatabase, ref, get } from 'firebase/database';
import { initializeApp } from 'firebase/app';
import firebaseConfig from '../../firebase/config/firebaseConfig';
import './Overhoring.css';
import { useLocation } from 'react-router-dom';

const firebaseApp = initializeApp(firebaseConfig);
const db = getDatabase(firebaseApp);

const WordQuiz = () => {
    const location = useLocation();
    const questionID = location.state && location.state.questionID;
    const [wordList, setWordList] = useState([]);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [userAnswer, setUserAnswer] = useState('');
    const [isFinished, setIsFinished] = useState(false);
    const [results, setResults] = useState([]);
    const [showAnswer, setShowAnswer] = useState(false);
    const [score, setScore] = useState(0);

    useEffect(() => {
        const fetchWords = async () => {
            if (questionID) {
                try {
                    const snapshot = await get(ref(db, `wordlist/${questionID}`));
                    if (snapshot.exists()) {
                        const data = snapshot.val();
                        setWordList(data.items || []);
                    }
                } catch (error) {
                    console.error('Fout bij het ophalen van gegevens:', error);
                }
            }
        };
        fetchWords();
    }, [questionID]);

    const handleAnswerSubmit = () => {
        const currentWord = wordList[currentQuestionIndex];
        if (!showAnswer) {
            const isCorrect = userAnswer === currentWord.item1;
            if (isCorrect) setScore(score + 1);
            setResults((prevResults) => [
                ...prevResults,
                {
                    vraag: currentWord.item2,
                    juisteAntwoord: currentWord.item1,
                    jouwAntwoord: userAnswer,
                    correct: isCorrect,
                },
            ]);
            setShowAnswer(true);
        } else {
            setShowAnswer(false);
            setUserAnswer('');
            if (currentQuestionIndex < wordList.length - 1) {
                setCurrentQuestionIndex(currentQuestionIndex + 1);
            } else {
                setIsFinished(true);
            }
        }
    };

    return (
        <div className="word-quiz-container">
            {isFinished ? (
                <div className="recap-screen">
                    <h2>Speloverzicht</h2>
                    <p>Jouw score: {score} / {wordList.length}</p>
                    <table>
                        <thead>
                        <tr>
                            <th>Vraag</th>
                            <th>Jouw Antwoord</th>
                            <th>Juiste Antwoord</th>
                        </tr>
                        </thead>
                        <tbody>
                        {results.map((result, index) => (
                            <tr key={index} className={result.correct ? 'correct' : 'incorrect'}>
                                <td>{result.vraag}</td>
                                <td>{result.jouwAntwoord}</td>
                                <td>{result.juisteAntwoord}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                    <button onClick={() => window.location.reload()}>Opnieuw spelen</button>
                </div>
            ) : (
                <div className="quiz-card">
                    <h2 className="text-light">Vraag {currentQuestionIndex + 1}</h2>
                    <p className="text-light">{wordList[currentQuestionIndex]?.item2}</p>
                    <input
                        type="text"
                        value={userAnswer}
                        onChange={(e) => setUserAnswer(e.target.value)}
                        disabled={showAnswer}
                    />
                    <button onClick={handleAnswerSubmit}>
                        {showAnswer ? 'Volgende' : 'Controleer'}
                    </button>
                    {showAnswer && (
                        <p className={results[currentQuestionIndex].correct ? 'correct' : 'incorrect'}>
                            {results[currentQuestionIndex].correct
                                ? 'Correct!'
                                : `Fout! Het juiste antwoord was: ${results[currentQuestionIndex].juisteAntwoord}`}
                        </p>
                    )}
                </div>
            )}
        </div>
    );
};

export default WordQuiz;
