// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.word-quiz-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Arial', sans-serif;
    padding: 20px;
    max-width: 600px;
    margin: 40px auto;
    color: #4b2e83;
}

.recap-screen {
    text-align: center;
}

table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

table, th, td {
    border: 1px solid #ddd;
    padding: 8px;
}

th {
    background-color: #f0e8ff;
}

tr:nth-child(even) {
    background-color: #f9f9f9;
}

tr.correct {
    background-color: #d4edda;
}

tr.incorrect {
    background-color: #f8d7da;
}

button {
    margin-top: 20px;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: #4b2e83;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s;
}

button:hover {
    background-color: #382f5f;
}

.correct {
    color: green;
}

.incorrect {
    color: red;
}
`, "",{"version":3,"sources":["webpack://./src/opdrachtControllers/Overhoring/Overhoring.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,gCAAgC;IAChC,aAAa;IACb,gBAAgB;IAChB,iBAAiB;IACjB,cAAc;AAClB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,yBAAyB;IACzB,gBAAgB;AACpB;;AAEA;IACI,sBAAsB;IACtB,YAAY;AAChB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,gBAAgB;IAChB,kBAAkB;IAClB,YAAY;IACZ,kBAAkB;IAClB,yBAAyB;IACzB,YAAY;IACZ,eAAe;IACf,iCAAiC;AACrC;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,UAAU;AACd","sourcesContent":[".word-quiz-container {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    font-family: 'Arial', sans-serif;\n    padding: 20px;\n    max-width: 600px;\n    margin: 40px auto;\n    color: #4b2e83;\n}\n\n.recap-screen {\n    text-align: center;\n}\n\ntable {\n    width: 100%;\n    border-collapse: collapse;\n    margin-top: 20px;\n}\n\ntable, th, td {\n    border: 1px solid #ddd;\n    padding: 8px;\n}\n\nth {\n    background-color: #f0e8ff;\n}\n\ntr:nth-child(even) {\n    background-color: #f9f9f9;\n}\n\ntr.correct {\n    background-color: #d4edda;\n}\n\ntr.incorrect {\n    background-color: #f8d7da;\n}\n\nbutton {\n    margin-top: 20px;\n    padding: 10px 20px;\n    border: none;\n    border-radius: 5px;\n    background-color: #4b2e83;\n    color: white;\n    cursor: pointer;\n    transition: background-color 0.3s;\n}\n\nbutton:hover {\n    background-color: #382f5f;\n}\n\n.correct {\n    color: green;\n}\n\n.incorrect {\n    color: red;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
