// import React, {useEffect} from "react";
// import { getDatabase, ref, set } from "firebase/database";
// import { initializeApp } from "firebase/app";
// import firebaseConfig from "../../firebase/config/firebaseConfig";
// import { useNavigate } from 'react-router-dom';
// import { useState } from "react";
// import {UserContext} from "../../pages/Login/UserContext";
//
// //this page is here so u can see all ur user info and change it if u want to
// const Accountpage = () => {
//     const firebaseApp = initializeApp(firebaseConfig);
//     const db = getDatabase(firebaseApp);
//     const navigate = useNavigate();
//     const [user, setUser] = useState(null);
//     const { user: loggedInUser } = React.useContext(UserContext);
//     setUser(loggedInUser);
//
//    
//
//
// export default Accountpage;