import React, { useContext } from 'react';
const LoginView = () => {
    return (
        <div className="d-flex justify-content-center align-items-center h-100">
            <div className="card mt-4">
                <div className="card-header">Login</div>
                <div className="card-body">
                    <div id="firebaseui-auth-container" lang="nl"></div>
                </div>
            </div>
        </div>
    );
};

export default LoginView;
