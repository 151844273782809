import React, { useState, useEffect } from 'react';
import { getDatabase, ref, onValue, set, push, remove, update } from 'firebase/database';
import { Modal, Button, Form } from 'react-bootstrap';

const ClassManager = () => {
    const [classes, setClasses] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [currentClass, setCurrentClass] = useState({ name: '' });
    const [isEditing, setIsEditing] = useState(false);

    const db = getDatabase();

    useEffect(() => {
        const classRef = ref(db, 'classes');
        onValue(classRef, (snapshot) => {
            if (snapshot.exists()) {
                setClasses(Object.entries(snapshot.val()).map(([key, value]) => ({ id: key, ...value })));
            }
        });
    }, []);

    const handleOpenModal = (edit = false, cls = { name: '' }) => {
        setIsEditing(edit);
        setCurrentClass(cls);
        setShowModal(true);
    };

    const handleInputChange = (e) => {
        setCurrentClass({ ...currentClass, [e.target.name]: e.target.value });
    };

    const handleSaveClass = async () => {
        try {
            const classRef = ref(db, isEditing ? `classes/${currentClass.id}` : 'classes');
            const method = isEditing ? update : push;
            await method(classRef, { name: currentClass.name });
            setShowModal(false);
        } catch (error) {
            alert(`Fout: ${error.message}`);
        }
    };

    const handleDeleteClass = async (classId) => {
        try {
            const classRef = ref(db, `classes/${classId}`);
            await remove(classRef);
        } catch (error) {
            alert(`Fout: ${error.message}`);
        }
    };

    return (
        <div className="container mt-3">
            <h2>Klassenbeheer</h2>
            <Button variant="primary" onClick={() => handleOpenModal()}>Nieuwe Klas Toevoegen</Button>
            <table className="table mt-3">
                <thead>
                <tr>
                    <th>Klasnaam</th>
                    <th>Acties</th>
                </tr>
                </thead>
                <tbody>
                {classes.map(cls => (
                    <tr key={cls.id}>
                        <td>{cls.name}</td>
                        <td>
                            <Button variant="secondary" onClick={() => handleOpenModal(true, cls)}>Bewerken</Button>
                            <Button variant="danger" onClick={() => handleDeleteClass(cls.id)}>Verwijderen</Button>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>

            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{isEditing ? 'Klas Bewerken' : 'Nieuwe Klas'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group>
                        <Form.Label>Klasnaam</Form.Label>
                        <Form.Control type="text" name="name" value={currentClass.name} onChange={handleInputChange} />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>Annuleren</Button>
                    <Button variant="primary" onClick={handleSaveClass}>Opslaan</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default ClassManager;
