// import { useState, useEffect } from 'react';
// import { getDatabase, ref, get } from 'firebase/database';
// import { initializeApp } from "firebase/app";
// import firebaseConfig from "../../firebase/config/firebaseConfig";
//    
// const firebaseApp = initializeApp(firebaseConfig);
// const db = getDatabase(firebaseApp);
//
// export const vragen = (questionID) => {
//     const [vragen, setVragen] = useState([]);
//     const [isLoading, setIsLoading] = useState(true);
//     const [error, setError] = useState(null);
//    
//     useEffect(() => {
//         const vragenRef = ref(db, 'wordlist/' + questionID);
//         get(vragenRef).then((snapshot) => {
//         if (snapshot.exists()) {
//             const vragenData = snapshot.val();
//             setVragen(vragenData);
//         } else {
//             setVragen([]);
//         }
//         setIsLoading(false);
//         }).catch((error) => {
//         setError(error);
//         setIsLoading(false);
//         });
//     }, [questionID]);
//    
//     return { vragen, isLoading, error };
//     }