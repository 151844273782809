import React from 'react';
import { Link } from 'react-router-dom';
import ClassManager from "./beheerKlassen";

const WordListLayout = ({ children }) => {
    const navbarStyle = {
        backgroundColor: '#652ea3', 
    };

    const linkStyle = {
        color: 'white', 
    };

    return (
        <div>
            <nav className="navbar navbar-expand-lg navbar-light mt-4" style={navbarStyle}>
                <button
                    className="navbar-toggler"
                    type="button"
                    data-toggle="collapse"
                    data-target="#wordlistNavbar"
                    aria-controls="wordlistNavbar"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse" id="wordlistNavbar">
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <Link to="/wordlist/add" className="nav-link" style={linkStyle}>Woordlijst toevoegen</Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/wordlist/manage" className="nav-link" style={linkStyle}>Woordlijst beheren</Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/wordlist/users" className="nav-link" style={linkStyle}>Gebruikers</Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/wordlist/ClassManager" className="nav-link" style={linkStyle}>Klassen</Link>
                        </li>
                    </ul>
                </div>
            </nav>
            <div className="container mt-4">
                {children}
            </div>
        </div>
    );
};

export default WordListLayout;
