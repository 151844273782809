import { useState, useEffect } from 'react';
import { getDatabase, ref, get } from 'firebase/database';
import { initializeApp } from "firebase/app";
import firebaseConfig from "../../firebase/config/firebaseConfig";

const firebaseApp = initializeApp(firebaseConfig);
const db = getDatabase(firebaseApp);

export const useDocentName = (docentID) => {
    const [docentName, setDocentName] = useState(''); // Assuming docentName is a string
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const docentNameRef = ref(db, 'users/' + docentID);
        get(docentNameRef).then((snapshot) => {
            if (snapshot.exists()) {
                const docentNameData = snapshot.val();
                setDocentName(docentNameData); // Ensure docentNameData is in the expected format
            } else {
                setDocentName(''); // Set to default empty string if not found
            }
            setIsLoading(false);
        }).catch((error) => {
            setError(error);
            setIsLoading(false);
        });
    }, [docentID]);

    return { docentName, isLoading, error };
}

export default useDocentName;
