import React, { useState, useEffect } from 'react';
import { getDatabase, ref, onValue, remove, update } from 'firebase/database';
import { Modal, Button, Form } from 'react-bootstrap';

const UserManager = () => {
    const [users, setUsers] = useState([]);
    const [classes, setClasses] = useState([]);
    const [classAssignments, setClassAssignments] = useState({});
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [currentUser, setCurrentUser] = useState(null);
    const [selectedClass, setSelectedClass] = useState('');
    const [actionType, setActionType] = useState('');

    const db = getDatabase();

    useEffect(() => {
        const usersRef = ref(db, 'users');
        onValue(usersRef, (snapshot) => {
            if (snapshot.exists()) {
                setUsers(Object.entries(snapshot.val()).map(([key, value]) => ({ uid: key, ...value })));
            }
        });

        const classRef = ref(db, 'classes');
        onValue(classRef, (snapshot) => {
            if (snapshot.exists()) {
                const fetchedClasses = Object.entries(snapshot.val()).map(([key, value]) => ({ id: key, ...value }));
                setClasses(fetchedClasses);
            }
        });
    }, []);

    const handleClassChange = (userId, classId) => {
        setClassAssignments({ ...classAssignments, [userId]: classId });
    };

    const assignClass = async (userId) => {
        try {
            const userRef = ref(db, `users/${userId}`);
            await update(userRef, { classId: classAssignments[userId] });
            alert('Klas succesvol toegewezen');
        } catch (error) {
            alert(`Fout: ${error.message}`);
        }
    };

    const handleAction = (user, action) => {
        setCurrentUser(user);
        setSelectedClass(user.classId || '');
        setActionType(action);
        setShowConfirmationModal(true);
    };

    const confirmAction = async () => {
        try {
            const updates = {};
            if (actionType === 'delete') {
                await remove(ref(db, `users/${currentUser.uid}`));
            } else {
                if (actionType === 'promote') {
                    updates['status'] = 2;
                    updates['classId'] = null;
                } else if (actionType === 'demote') {
                    updates['status'] = 1;
                    updates['classId'] = selectedClass;
                }
                await update(ref(db, `users/${currentUser.uid}`), updates);
            }
            setShowConfirmationModal(false);
        } catch (error) {
            alert(`Fout: ${error.message}`);
        }
    };

    const getStatusButton = (user) => {
        return user.status === 2
            ? <Button variant="warning" onClick={() => handleAction(user, 'demote')}>Degradatie naar Gebruiker</Button>
            : <Button variant="success" onClick={() => handleAction(user, 'promote')}>Promoveren naar Docent</Button>;
    };

    return (
        <div className="container mt-3">
            <h2>Gebruikersbeheer</h2>
            <table className="table">
                <thead>
                <tr>
                    <th>Naam</th>
                    <th>Email</th>
                    <th>Klas</th>
                    <th>Acties</th>
                </tr>
                </thead>
                <tbody>
                {users.map(user => (
                    <tr key={user.uid}>
                        <td>{user.displayName}</td>
                        <td>{user.email}</td>
                        <td>
                            {user.status !== 2 && (
                                <div>
                                    <Form.Select
                                        value={classAssignments[user.uid] || ''}
                                        onChange={(e) => handleClassChange(user.uid, e.target.value)}
                                    >
                                        <option value="">Selecteer een klas</option>
                                        {classes.map(cls => (
                                            <option key={cls.id} value={cls.id}>{cls.name}</option>
                                        ))}
                                    </Form.Select>
                                    <Button
                                        variant="primary"
                                        onClick={() => assignClass(user.uid)}
                                        className="mt-2"
                                    >
                                        Klas Toewijzen
                                    </Button>
                                </div>
                            )}
                        </td>
                        <td>
                            {getStatusButton(user)}
                            <Button variant="danger" onClick={() => handleAction(user, 'delete')}>Verwijderen</Button>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>

            <Modal show={showConfirmationModal} onHide={() => setShowConfirmationModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Actie Bevestigen</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {actionType === 'delete' && <p>Weet je zeker dat je {currentUser?.displayName} wilt verwijderen?</p>}
                    {actionType === 'promote' && <p>Weet je zeker dat je {currentUser?.displayName} wilt promoveren naar Docent?</p>}
                    {actionType === 'demote' && (
                        <>
                            <p>Weet je zeker dat je {currentUser?.displayName} wilt degraderen naar Gebruiker?</p>
                            <Form.Group className="mb-3">
                                <Form.Label>Klas</Form.Label>
                                <Form.Select
                                    value={selectedClass}
                                    onChange={(e) => setSelectedClass(e.target.value)}
                                >
                                    <option value="">Selecteer een klas</option>
                                    {classes.map(cls => (
                                        <option key={cls.id} value={cls.id}>{cls.name}</option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                        </>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowConfirmationModal(false)}>Annuleren</Button>
                    <Button variant="primary" onClick={confirmAction}>Bevestigen</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default UserManager;
