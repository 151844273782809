import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import './custom.scss';
import Navbar from './Navbar/Navbar';
import './Style/ColourScheme.css';
import Content from './Content';
import './Style/ColourScheme.css';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
    <Content />
  </>
);







// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
