import React, { useContext } from 'react';
import { getAuth, signOut } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../pages/Login/UserContext'; 
import NavbarDropdown from "./DropdownNavbar";
import NavbarContent from "./NavbarContent";
import '../Style/ColourScheme.css';


function Navbar() {
    const navigate = useNavigate();
    const { user, setUser } = useContext(UserContext);

    const handleLogout = () => {
        const auth = getAuth();
        signOut(auth).then(() => {
            setUser(null);
            navigate('/login');
        }).catch((error) => {
            console.error('Logout Error:', error);
        });
    };

    if (user) {
      if (user.status === 2) {
        return (
        <>
            <div className="container-fluid">
            <div className='row d-none d-md-flex mx-n3'>
                <div className='col bg-purple-dark rounded-bottom-right py-3'>

                    <h1 className='text-white text-center'><img src="/favicon.ico" alt="Logo" width="50" height="50" className="d-inline-block align-text-top"/>SlimStudie
                    </h1>
                </div>

                <NavbarContent Link={'/'} Title={'Home'}/>
                <NavbarContent Link={'#'} Title={'Toetsen'}/>
                <NavbarContent Link={'/account'} Title={'Account'}/>
                <NavbarContent Link={'/docent'} Title={'Docentmanagment'}/>
    
                
                <div className='col bg-purple-dark ms-2 mb-3 p-lg-3 py-3 d-flex justify-content-center'>
                    <a className="text-decoration-none">
                        <button onClick={handleLogout} className="btn btn-logout"><h3 className="text-white text-center">Uitloggen <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-box-arrow-right" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0z"/>
                                <path fill-rule="evenodd" d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708z"/>
                            </svg>
                        </h3></button>
                    </a>
                </div>
    
                <NavbarDropdown/>
            </div>
            </div>
        </>
        );
    }
    else {

  return (
      <>
        <div className="container-fluid">
          <div className='row d-none d-md-flex mx-n3'>
            <div className='col bg-purple-dark rounded-bottom-right py-3'>
              <h1 className='text-white text-center'>SlimStudie</h1>
            </div>

            <NavbarContent Link={'/'} Title={'Home'}/>
            <NavbarContent Link={'#'} Title={'Toetsen'}/>
            <NavbarContent Link={'#'} Title={'Instructies'}/>
            
            {/* Logout Button */}
            <div className='col-auto d-flex align-items-center'>
              <button onClick={handleLogout} className="btn btn-logout">Logout</button>
            </div>

            <NavbarDropdown/>
          </div>
        </div>
      </>
  );
}}}

export default Navbar;
