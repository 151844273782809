import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import LoginView from './LoginView';
import { signInWithFirebase } from './LoginModel.js';
import { UserContext } from './UserContext.js';

const LoginController = () => {
    const [user, setUser] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        signInWithFirebase(setUser, navigate);
    }, [navigate]);

    return (
        <UserContext.Provider value={{ user, setUser }}>
            <LoginView />
        </UserContext.Provider>
    );
};

export default LoginController;
