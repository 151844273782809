import React, { useState, useEffect } from 'react';
import { getDatabase, ref, get } from 'firebase/database';
import { initializeApp } from "firebase/app";
import firebaseConfig from "../../firebase/config/firebaseConfig";
import './Woordzoeker.css';
import { useLocation } from "react-router-dom";

const firebaseApp = initializeApp(firebaseConfig);
const db = getDatabase(firebaseApp);

const gridSize = 10;
const directions = [[1, 0], [0, 1], [1, 1], [1, -1]]; // Right, Down, Diagonal Right Down, Diagonal Right Up

const WordSearch = () => {
    const location = useLocation();
    const questionID = location.state && location.state.questionID;
    const [grid, setGrid] = useState(Array.from({ length: gridSize }, () => Array(gridSize).fill('')));
    const [wordList, setWordList] = useState([]);
    const [selectedCells, setSelectedCells] = useState([]);
    const [mouseIsDown, setMouseIsDown] = useState(false);
    const [foundWords, setFoundWords] = useState([]);
    const [isFinished, setIsFinished] = useState(false);
    const [foundWordsDetails, setFoundWordsDetails] = useState([]);


    useEffect(() => {
        const fetchWords = async () => {
            if (questionID) {
                try {
                    const snapshot = await get(ref(db, `wordlist/${questionID}`));
                    if (snapshot.exists()) {
                        const data = snapshot.val();
                        setWordList(data.items || []);
                        generateGrid(data.items || []);
                    }
                } catch (error) {
                    console.error("Error fetching data:", error);
                }
            }
        };
        fetchWords();
    }, [questionID]);

    const generateGrid = (words) => {
        let newGrid = Array.from({ length: gridSize }, () => Array(gridSize).fill(''));
        words.forEach(wordObject => {
            const word = wordObject.item1;
            let placed = false;
            while (!placed) {
                placed = placeWordInGrid(newGrid, word);
            }
        });
        fillEmptySpaces(newGrid);
        setGrid(newGrid);
    };

    const placeWordInGrid = (grid, word) => {
        const dir = directions[Math.floor(Math.random() * directions.length)];
        const startPos = [
            Math.floor(Math.random() * gridSize),
            Math.floor(Math.random() * gridSize)
        ];

        for (let i = 0; i < word.length; i++) {
            const row = startPos[0] + i * dir[0];
            const col = startPos[1] + i * dir[1];
            if (!isValidPosition(grid, row, col, word[i])) return false;
        }

        for (let i = 0; i < word.length; i++) {
            const row = startPos[0] + i * dir[0];
            const col = startPos[1] + i * dir[1];
            grid[row][col] = word[i];
        }
        return true;
    };

    const isValidPosition = (grid, row, col, letter) => {
        return row >= 0 && row < gridSize && col >= 0 && col < gridSize && (grid[row][col] === '' || grid[row][col] === letter);
    };

    const fillEmptySpaces = (grid) => {
        for (let row = 0; row < gridSize; row++) {
            for (let col = 0; col < gridSize; col++) {
                if (grid[row][col] === '') {
                    grid[row][col] = String.fromCharCode(65 + Math.floor(Math.random() * 26)); // Random letter A-Z
                }
            }
        }
    };

    const handleMouseDown = (row, col) => {
        setMouseIsDown(true);
        setSelectedCells([[row, col]]);
    };

    const handleMouseOver = (row, col) => {
        if (mouseIsDown) {
            setSelectedCells(oldCells => [...oldCells, [row, col]]);
        }
    };

    const handleMouseUp = () => {
        setMouseIsDown(false);
        checkWordSelection();
        setSelectedCells([]);
    };

    const checkWordSelection = () => {
        let selectedWord = selectedCells.map(([row, col]) => grid[row][col]).join('');
        wordList.forEach(wordObj => {
            if (wordObj.item1 === selectedWord && !foundWords.includes(selectedWord)) {
                setFoundWords(oldFoundWords => [...oldFoundWords, selectedWord]);
                if (foundWords.length + 1 === wordList.length) {
                    setIsFinished(true);
                }
            }
        });
    };

    return (
        <div className="word-search-container">
            {isFinished ? (
                <div className="finished-screen">
                    Gefeliciteerd! Je hebt alle woorden gevonden.
                    <div className="row">
                        <div className="col-6">
                            <button className="btn btn-primary" onClick={() => setIsFinished(false)}>Opnieuw</button>
                        </div>
                        <div className="col-6">

                            <button className="btn btn-primary" onClick={() => window.location.href = "/"}>Terug naar menu</button>
                        </div>
                    </div>
                </div>
            ) : (
                <div>
                    <div className="grid">
                        {grid.map((row, rowIndex) => (
                            <div key={rowIndex} className="row">
                                {row.map((cell, colIndex) => (
                                    <div
                                        key={colIndex}
                                        className={`cell ${selectedCells.some(cell => cell[0] === rowIndex && cell[1] === colIndex) ? 'selected' : ''}`}
                                        onMouseDown={() => handleMouseDown(rowIndex, colIndex)}
                                        onMouseOver={() => handleMouseOver(rowIndex, colIndex)}
                                        onMouseUp={handleMouseUp}
                                    >
                                        {cell}
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>
                    <div className="word-list">
                        {wordList.map((wordObject, index) => (
                            <div key={index} className={`word ${foundWords.includes(wordObject.item1) ? 'found' : ''}`}>
                                {wordObject.item1}
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default WordSearch;
